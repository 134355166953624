<template>
  <modal
      @close="$emit('close')"
      class="custom-popup request-payment-popup"
      :bodyZPosition="true"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_ReturnedGoods',])"></div>
        {{$t('returnGoods_ReturnedGoods.localization_value.value')}}
        #{{ returnGoodsId }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'payments_transaction',
              'unidentifiedP_messageToAdmin',
              'common_cancel',
              'common_send',
              ])"></span>
        <div class="custom-row">
          <div class=" custom-col">
            <UserSelect
                :validationUser="userError"
                :validationTranslateUser="'error_required'"
                :userSelected="user"
                :typeSelect="'users'"
                :userEmail="true"
                :label="'common_user'"
                @changeUser="changeUser"
            />
          </div>

        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            v-if="returnGoodsId"
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="attachUserValidation"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";
import {filterFunc} from "@/mixins/filterFuncMixin/filterFunc";

export default {
  name: "AddUserReturnGoodsPopup",
  components: {
    UserSelect,
    Modal,
    MainButton,
  },

  mixins: [returnGoodsMixin, filterFunc],

  props: {
    returnGoodsId: Number,
  },

  data() {
    return {
      user: '',
      userError: false,
    }
  },

  mounted() {

  },

  methods: {

    attachUserValidation() {
      this.userError = false
      if(!this.user || this.user === '') return this.userError = true

      this.attachUser(this.returnGoodsId, this.user.id, true)
    },



    changeUser(val){
      this.user = val
    },
  }
}

</script>

<style lang="scss">
.request-payment-popup {

  .modal-component__inner {
    max-width: 685px;
  }
}


</style>
